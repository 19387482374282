import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import workerImg from '../../media/worker.png';
import { Backdrop, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, styled } from '@mui/material';
import { FontDownload, Padding, WhatsApp } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#3a3a3a69',
    border: '1px solid #c8c8c852',
    borderRadius: theme.spacing(0.5),
    backdropFilter: 'blur(15px)', // Add blur effect
    '-webkit-backdrop-filter': 'blur(15px)', // Add blur effect for Safari
    color: '#FFF',
    paddingBottom: theme.spacing(2),
  },
  '& .MuiDialogTitle-root': {
    fontWeight: 300,
    color: '#FFF',
  },
  '& .MuiDialogContentText-root': {
    fontWeight: 300,
    color: '#FFF',
  },
}));

const style = {};

interface Props {
  percentAmount: string;
}

export const JobModal: React.FC<Props> = ({ percentAmount }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div style={{ position: 'relative', paddingTop: percentAmount, margin: '16px', cursor: 'pointer' }} onClick={handleOpen}>
        <img style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }} src={workerImg} alt="Carrousel Image" />
      </div>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        hideBackdrop
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Jesteś zainteresowany współpracą z nami?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">Możesz to zrobić, kontaktuj się z nami.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: 400, textTransform: 'none', mx: 1.5 }}
            size="large"
            variant="contained"
            fullWidth
            startIcon={<WhatsApp sx={{ color: '#24d366' }} />}
            onClick={() => window.open('https://wa.me/+48729883891')}
          >
            Kontaktuj się z nami!
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
};
