import styled from '@emotion/styled';
import { AppBar, Box, Button, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

export const SecondHeader = styled(AppBar)(() => ({
  height: 90,
  background: '#10235A',
}));

export const MenuBox = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const OutterResponsiveMenuBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
}));

export const InnerResponsiveMenuBox = styled(Box)(() => ({
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  height: 70,
  flexGrow: 1,
}));

export const MenuButton = styled(Button)(() => ({
  fontSize: 18,
  color: 'white',
  display: 'block',
  fontWeight: 300,
  letterSpacing: 2,
  '&:hover': {
    backgroundColor: '#344884',
  },
}));

export const ResponsiveMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#3a3a3a69',
    textTransform: 'uppercase',
    border: '1px solid #c8c8c852',
    backdropFilter: 'blur(5px)', // Add blur effect
    '-webkit-backdrop-filter': 'blur(5px)', // Add blur effect for Safari
  },
  marginTop: 25,
}));

export const ResponsiveMenuItem = styled(MenuItem)(() => ({
  fontSize: 16,
  color: '#FFF',
  fontWeight: 300,
  letterSpacing: 2,
}));

export const ResponsiveListItemIcon = styled(ListItemIcon)(() => ({
  color: '#CECFCE',
  marginBottom: 3,
}));

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
  borderRadius: 5,
  '&:hover': {
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));
