import { FC } from 'react';
import { Box, Button, Container, createTheme, CssBaseline, Grid, Paper, ThemeProvider, Typography } from '@mui/material';
import Footer from './footer/Footer';
import Layout from './routes/Layout';
import { LocationOn, Phone, Email, ArrowBack, Home } from '@mui/icons-material';
import contactImg from '../media/contact.png';
import useTranslations from './global/translation/hooks/translation';
import { useNavigate } from 'react-router-dom';

export const theme = createTheme({
  palette: {
    background: {
      default: '#0d152d',
    },
  },
});

const NotFound: FC = () => {
  const { currentLanguage, translations } = useTranslations();
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <header style={{ position: 'sticky', top: 0, zIndex: 1 }}>
        <nav>
          <Layout />
        </nav>
      </header>
      <Container>
        <section>
          <Grid container justifyContent="center" alignItems="center" p={6}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={300} color={'#FFF'} textAlign="center">
                {translations.other.pageNotFound[currentLanguage]}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ position: 'relative', paddingTop: { xs: '40vh', md: '45vh' }, margin: '16px' }}>
                <img
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
                  src={contactImg}
                  alt="Carrousel Image"
                />
              </Box>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button onClick={() => navigate('/')} startIcon={<Home />} variant="outlined">
                {translations.other.goHome[currentLanguage]}
              </Button>
            </Grid>
          </Grid>
        </section>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default NotFound;
