// translations/buttons.ts
const buttonTranslations: Record<string, Record<string, string>> = {
  previousDay: {
    en: 'Previous Day',
    pl: 'Poprzedni Dzień',
  },
  nextDay: {
    en: 'Next Day',
    pl: 'Następny Dzień',
  },
  booking: {
    en: 'Booking',
    pl: 'Dodaj termin',
  },
  apply: {
    en: 'Apply',
    pl: 'Zastosuj grafik',
  },
  settings: {
    en: 'Settings',
    pl: 'Ustawienia',
  },
  submit: {
    en: 'Submit',
    pl: 'Zatwierdź',
  },
  cancel: {
    en: 'Cancel',
    pl: 'Anuluj',
  },
  save: {
    en: 'Save',
    pl: 'Zapisz',
  },
  back: {
    en: 'Back',
    pl: 'Wstecz',
  },
  next: {
    en: 'Next',
    pl: 'Dalej',
  },
  signIn: {
    en: 'Sign in',
    pl: 'Zaloguj się',
  },
  forgotPassword: {
    en: 'Forgot password?',
    pl: 'Zapomniałeś hasła?',
  },
  changePassword: {
    en: 'Change password',
    pl: 'Zmień hasło',
  },
  dontHaveAcc: {
    en: `Don't have an account?`,
    pl: 'Nie masz konta?',
  },
  tryIt: {
    en: 'Try it',
    pl: 'Spróbuj',
  },
  reset: {
    en: 'Reset',
    pl: 'Zresetuj',
  },
  // Add more translations as needed
};

export default buttonTranslations;
