import { AppBar, Box, styled, Toolbar } from '@mui/material';
import { FC } from 'react';

interface Props {}

const FooterAppBar = styled(AppBar)(() => ({
  height: 45,
  backgroundColor: '#061540',
}));

const Footer: FC<Props> = () => {
  return (
    <footer style={{ bottom: 0, position: 'fixed', width: '100vw', zIndex: 1400 }}>
      <FooterAppBar position="static">
        <Toolbar style={{ minHeight: 41, fontWeight: 100 }}>
          <Box sx={{ flexGrow: 0.5 }} />
          Copyright {new Date().getFullYear()} - Designed by &nbsp;
          <a href="https://alfonso-dev.com/" style={{ textDecoration: 'none', color: '#FFF' }}>
            <b>Alphus</b>
          </a>
        </Toolbar>
      </FooterAppBar>
    </footer>
  );
};

export default Footer;
