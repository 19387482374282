import { FC } from 'react';
import Grid from '@mui/material/Grid';
import ponchoImg from '../../media/poncho.png';
import jaroImg from '../../media/jaro.png';
import mariaImg from '../../media/maria.png';
import basiaImg from '../../media/basia.png';
import zusiaImg from '../../media/zusia.png';
import olaImg from '../../media/ola.png';
import { Typography } from '@mui/material';
import useTranslations from '../global/translation/hooks/translation';
import { JobModal } from '../global/JobModal';

const Team: FC = () => {
  const { currentLanguage, translations } = useTranslations();
  const percentAmount = '8rem';
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} m={2}>
        <Typography variant="h4" fontWeight={300} color={'#FFF'} textAlign="center">
          {translations.menuItems.ourTeam[currentLanguage]}
        </Typography>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={4} md={4}>
              <div style={{ position: 'relative', paddingTop: percentAmount, margin: '16px' }}>
                <img
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
                  src={ponchoImg}
                  alt="Carrousel Image"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div style={{ position: 'relative', paddingTop: percentAmount, margin: '16px' }}>
                <img
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
                  src={basiaImg}
                  alt="Carrousel Image"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div style={{ position: 'relative', paddingTop: percentAmount, margin: '16px' }}>
                <img
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
                  src={jaroImg}
                  alt="Carrousel Image"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={4} md={4}>
              <div style={{ position: 'relative', paddingTop: percentAmount, margin: '16px' }}>
                <img
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
                  src={zusiaImg}
                  alt="Carrousel Image"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div style={{ position: 'relative', paddingTop: percentAmount, margin: '16px' }}>
                <img
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
                  src={mariaImg}
                  alt="Carrousel Image"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div style={{ position: 'relative', paddingTop: percentAmount, margin: '16px' }}>
                <img
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
                  src={olaImg}
                  alt="Carrousel Image"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs>
              <JobModal percentAmount={percentAmount} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Team;
