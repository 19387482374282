import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import mobileMenuReducer from './components/header/appBars/mobileMenu/menuSlice';
import translationReducer from './components/global/translation/translationSlice';

export const store = configureStore({
  reducer: {
    mobileMenuSliceState: mobileMenuReducer,
    selectTranslation: translationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
