import { FC } from 'react';
import FirstAppBar from '../header/appBars/desktop/FirstAppBar';
import SecondAppBar from '../header/appBars/desktop/SecondAppBar';

interface Props {}

const Layout: FC<Props> = () => {
  return (
    <>
      <FirstAppBar />
      {/* Routes for the Layout are inside the SecondAppBar component */}
      <SecondAppBar />
    </>
  );
};

export default Layout;
