// translations/menuItems.ts
const menuTranslations: Record<string, Record<string, string>> = {
  timeslots: {
    en: 'Timeslots',
    pl: 'Planer',
  },
  schedule: {
    en: 'Schedules',
    pl: 'Grafiki',
  },
  aboutUs: {
    en: 'About Us',
    pl: 'O nas',
  },
  ourTeam: {
    en: 'Our Team',
    pl: 'Nasza Ekipa',
  },
  offer: {
    en: 'Offer',
    pl: 'Oferta',
  },
  method: {
    en: 'Method',
    pl: 'Metody',
  },
  pension: {
    en: 'Pension',
    pl: 'Pensjonat',
  },
  services: {
    en: 'Services',
    pl: 'Usługi',
  },
  pricing: {
    en: 'Pricing',
    pl: 'Cennik',
  },
  settings: {
    en: 'Settings',
    pl: 'Ustawienia',
  },
  manage: {
    en: 'Manage',
    pl: 'Zarządzaj',
  },
  resources: {
    en: 'Resources',
    pl: 'Zasoby',
  },
  staff: {
    en: 'Staff',
    pl: 'Personel',
  },
  account: {
    en: 'Account',
    pl: 'Konto',
  },
  contact: {
    en: 'Contact',
    pl: 'Kontakt',
  },
  // Add more translations as needed
};

export default menuTranslations;
