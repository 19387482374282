import { Button, Grid, Tooltip, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import useTranslations from '../global/translation/hooks/translation';

type Props = {};

const Mission = (props: Props) => {
  const { currentLanguage, translations } = useTranslations();
  return (
    <Grid container color="#FFF" sx={{ bgcolor: '#010921' }} justifyContent="center">
      <Grid item py={12} px={'4rem'} justifyItems="center">
        <Typography variant="h6" fontWeight={300} textAlign="center" gutterBottom>
          {translations.label.heroText[currentLanguage]}
        </Typography>
        <Tooltip
          title="Wkrótce dostępne"
          arrow
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '1rem', // Increase font size
                padding: '8px 12px', // Optional: add more padding for better appearance
                fontWeight: 'medium',
              },
            },
          }}
        >
          <span>
            <Button
              size="large"
              variant="contained"
              // onClick={() => window.open('https://lubie.urbanequestrian.pl/', '_blank')}
              // disabled
              sx={{
                mt: 2,
                bgcolor: 'rgb(33, 53, 112)',
                color: 'white',
                fontWeight: 'bold',
                '&:hover': {
                  bgcolor: 'rgb(43, 63, 132)', // Slightly lighter for hover
                  transform: 'scale(1.05)',
                  transition: 'all 0.3s',
                },
                boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                borderRadius: '8px',
                px: 3,
                '&.Mui-disabled': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  backgroundColor: 'rgba(33, 53, 112, 0.7)',
                },
              }}
              startIcon={<EventIcon />}
            >
              Umów się na trening
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Mission;
