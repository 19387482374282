import { FC } from 'react';
import { AppBar, Box, Button, IconButton, SvgIcon, Toolbar } from '@mui/material';
import styled from '@emotion/styled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setLanguage } from '../../../global/translation/translationSlice';
import { RootState } from '../../../../store';
import translations from '../../../global/translation';

const FirstHeader = styled(AppBar)(() => ({
  height: 41,
  backgroundColor: '#213570',
}));

const FirstAppBar: FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const currentLanguage = useAppSelector((state: RootState) => state.selectTranslation.currentLanguage);
  const dispatch = useAppDispatch();
  return (
    <>
      <FirstHeader position="static">
        <Toolbar style={{ minHeight: 41 }}>
          <IconButton
            size="small"
            color="inherit"
            sx={{ mr: 1 }}
            onClick={() => {
              window.open('https://www.facebook.com/profile.php?id=100063462341954');
            }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            sx={{ mr: 1 }}
            onClick={() => {
              window.open('https://www.instagram.com/urbanequestrian_pl/?locale=pl&hl=am-et');
            }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            sx={{ mr: 1 }}
            onClick={() => {
              window.open('https://www.tiktok.com/@urban.equestrian');
            }}
          >
            <SvgIcon>
              <svg xmlns="http://www.w3.org/2000/svg" fill="#FFF" viewBox="0 -70 448 650" strokeWidth={1.5} stroke="currentColor">
                <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
              </svg>
            </SvgIcon>
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            sx={{ mr: 1 }}
            onClick={() => {
              window.open(
                'https://www.google.com/maps/dir//Urban+Equestrian,+Koszary/@51.1245855,21.1794487,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47186ddde34b675b:0xed82cb2b23b6e247!2m2!1d21.2494807!2d51.1245984'
              );
            }}
          >
            <LocationOnIcon />
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            sx={{ mr: 1 }}
            onClick={() => {
              window.open('mailto:basia0urban@gmail.com');
            }}
          >
            <MailIcon />
          </IconButton>
          {matches ? (
            <IconButton
              size="small"
              color="inherit"
              sx={{ mr: 1 }}
              onClick={() => {
                window.open('tel:729883891', '_self');
              }}
            >
              <CallIcon />
            </IconButton>
          ) : (
            <Button size="small" color="inherit" sx={{ ml: 1 }} onClick={() => window.open('tel:729883891', '_self')} startIcon={<CallIcon />}>
              {translations.label.directContact[currentLanguage] + ` (+48) 729 883 891`}
            </Button>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button
            onClick={() => {
              currentLanguage === 'en' ? dispatch(setLanguage('pl')) : dispatch(setLanguage('en'));
            }}
            size="large"
            color="inherit"
            style={{ marginRight: '6vmin' }}
          >
            {currentLanguage === 'en' ? 'PL' : 'EN'}
          </Button>
        </Toolbar>
      </FirstHeader>
    </>
  );
};

export default FirstAppBar;
