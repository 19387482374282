// translations/buttons.ts
const loadingTranslations: Record<string, Record<string, string>> = {
  bookings: {
    en: 'Loading bookings...',
    pl: 'Ładowanie rezerwacji...',
  },
  timeslots: {
    en: 'Loading timeslots...',
    pl: 'Ładowanie okna czasowe...',
  },
  staff: {
    en: 'Loading staff...',
    pl: 'Ładowanie personel...',
  },
  schedule: {
    en: 'Loading schedule...',
    pl: 'Ładowanie grafik',
  },
  services: {
    en: 'Loading services...',
    pl: 'Ładowanie usługi...',
  },
  resources: {
    en: 'Loading resources...',
    pl: 'Ładowanie zasobów...',
  },
  settings: {
    en: 'Loading settings...',
    pl: 'Ładowanie ustawień...',
  },
  // Add more translations as needed
};

export default loadingTranslations;
