import { FC } from 'react';
import Grid from '@mui/material/Grid';
import methodImg from '../../media/method.png';
import { Paper, Typography } from '@mui/material';
import useTranslations from '../global/translation/hooks/translation';
import Paragraphs from '../global/Paragraphs';

const Method: FC = () => {
  const { currentLanguage, translations } = useTranslations();
  const text = translations.label.methodText[currentLanguage];
  const paragraphs = text.split('\n\n');
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} m={2}>
        <Typography variant="h4" fontWeight={300} color={'#FFF'} textAlign="center">
          {translations.menuItems.method[currentLanguage]}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={12} sx={{ p: 2, m: 2, bgcolor: '#ebebeb4f' }}>
          <Typography variant="subtitle2" fontWeight={300} color={'#FFF'} textAlign="justify">
            <Paragraphs paragraphs={paragraphs} />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ position: 'relative', paddingTop: '35%', margin: '16px' }}>
          <img
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'scale-down' }}
            src={methodImg}
            alt="Carrousel Image"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Method;
